<script>
    import { required, maxLength } from "vuelidate/lib/validators";
    import validationMessages from '@/components/validations'
    import Ticketing from '@/services/Ticketing';


    export default {
        components:{
            validationMessages
        },
        props: ['order_line_id'],
        data() {
            return {
                csrf_token: localStorage.getItem('csrf_token'),
                attributes: [],
                showLoader: false,
                attribute_value: null,
                submitted: false,
                showModal: false,
                tryingToSubmit: false,
                subscriptionTagsTypes:[],
            };
        },
        validations: {
            attributes: {
                $each: {
                    name: {
                        required
                    },
                    value: {
                        required,
                        maxLength: maxLength(100),
                    }
                },
            },
        },
        computed: {
            firstObject() {
                return Object.values(this.subscriptionTagsTypes)[0];
            },
            isFirstObjectNameSet() {
                return Boolean(this.firstObject && this.firstObject.name);
            }
        },
        methods: {

            addAttributeInput() {
                if(this.attributes.some(data => data.name.trim() === '')){
                    this.submitted = true
                    this.$v.$touch()
                }else{
                    if(this.isFirstObjectNameSet){
                        this.attributes.push({ name: this.firstObject.name, value:'' });
                    }
                    this.submitted = false
                }
            },

            async removeAdvertisementAttribute(index, attribute){
                this.attributes.splice(index, 1);
                const params = 'subscription_number='+this.order_line_id+'&name='+attribute.name
                await Ticketing.deleteSubscriptionTags(params).then((response) => {
                }).catch(error => {
                    this.error = error.response.data.error ? error.response.data.error : "";
                    //this.failedmsg("Can not delete attribute")
                }).finally(() => {

                })
            },

            async getSubscriptionTagsTypes(){
                try {
                    await Ticketing.getSubscriptionTagsTypes({

                    }).then((response) => {

                        const tagtypes = {};

                        response.data.data.forEach(obj => {
                            tagtypes[obj.name] = obj;
                        });
                        this.subscriptionTagsTypes = tagtypes

                    }).catch(error => {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg("Can not get seasons")
                    }).finally(() => {

                    })

                } catch (error) {
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.failedmsg("Can not get Subscription Tag Types")
                }
            },

            async getSeasonTicketTags(){
                this.showLoader = true
                const response = await Ticketing.getSeasonTicketTags(this.order_line_id)
                if(response.data && response.data.data.length){
                    this.attributes = []
                    response.data.data.forEach((value) => {
                        this.attributes.push({name: value.name, value: value.value})
                    });
                }else{
                    if(this.attributes.some(data => data.name.trim() === '')){
                        if(this.isFirstObjectNameSet){
                            this.attributes.push({ name: this.firstObject.name, value:'' });
                        }
                    }
                }
                this.showLoader = false
            },

            async syncSeasonTicketAttributes(){
                this.tryingToSubmit = true;
                this.submitted = true;
                this.$v.$touch()

                if (this.$v.$invalid) {
                    this.tryingToSubmit = false;
                    return;
                } else {
                    try {
                        await Ticketing.updateSubscriptionTags({
                            subscription_number: this.order_line_id,
                            attributes: this.attributes,
                            csrf_token: this.csrf_token
                        }).then((response) => {
                            this.successmsg("Change successfully saved");
                            this.closeModal();
                            this.refreshData();
                        }).catch(error => {
                            this.error = error.response.data.error ? error.response.data.error : "";
                            this.failedmsg(this.error)
                        }).finally(() => {
                            this.tryingToSubmit = false;
                        })
                    } catch (error) {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg(this.error)
                    }
                }
                this.tryingToSubmit = false;
            },

            refreshData() {
                this.$emit('onRefresh') //event from parent
            },

            closeModal() {
                this.showModal = false;
                this.submitted = false;
                this.attributes = []
            },

            modalShownCalls(){
                this.getSeasonTicketTags()
                this.getSubscriptionTagsTypes()
            }
        }
    };
</script>

<template>
    <b-modal @shown="modalShownCalls" id="manage_season_ticket_attributes" size="lg" v-model="showModal"
             title="Add New Season Ticket Tags" title-class="font-18" centered>

        <form @submit.prevent="syncSeasonTicketAttributes" v-if="!showLoader">
            <div class="row mb-3" v-for="(attribute, index) in attributes" :key="index">
                <template v-if="subscriptionTagsTypes[attribute.name]">
                    <div class="col-sm-12 col-md-6 mb-sm-3 mb-md-0">

                        <b-form-group label="Tag Name" :label-for="'attributename_'+index">
                            <b-form-select
                                    v-model="attribute.name"
                                    :id="'attributename_'+index"
                                    class="form-select"
                                    :class="{
                                         'is-invalid': submitted && $v.attributes.$each[index].name.$error,
                                    }"
                            >
                                <option v-for="option in subscriptionTagsTypes" :value="option.name" :key="option.name">
                                    {{ option.name }}
                                </option>
                            </b-form-select>
                            <validationMessages v-if="submitted" :fieldName="'Tag Name'"
                                                :validationName="$v.attributes.$each[index].name"></validationMessages>
                        </b-form-group>
                    </div>
                    <div class="col-sm-12 col-md-6 mb-sm-3 mb-md-0">
                        <b-form-group label="Tag Value" :label-for="'attributevalue_'+index" class="d-inline-block w-50">

                            <template v-if="subscriptionTagsTypes[attribute.name].strict">
                                <b-form-select
                                        class="form-select"
                                        :id="'attributevalue_'+index"
                                        v-model="attribute.value"
                                        :class="{
                                        'is-invalid': submitted && $v.attributes.$each[index].value.$error,
                                    }"
                                >
                                    <option v-for="option in subscriptionTagsTypes[attribute.name].values" :value="option" :key="option">
                                        {{ option }}
                                    </option>
                                </b-form-select>
                                <validationMessages v-if="submitted" :fieldName="'Tag Value'"
                                                    :validationName="$v.attributes.$each[index].value"></validationMessages>
                            </template>
                            <template v-else>
                                <b-form-input
                                        :id="'attributevalue_'+index"
                                        v-model="attribute.value"
                                        :class="{
                                        'is-invalid': submitted && $v.attributes.$each[index].value.$error,
                                    }"
                                >
                                </b-form-input>
                                <validationMessages v-if="submitted" :fieldName="'Tag Value'"
                                                    :validationName="$v.attributes.$each[index].value"></validationMessages>
                            </template>

                        </b-form-group>
                        <div v-if="index > 0 || attribute.name != '' " class="d-inline-block ms-3">
                            <a href="javascript:void(0);" title="Remove Tag" class="text-danger" @click="removeAdvertisementAttribute(index, attribute)">
                                <i class="uil uil-trash-alt font-size-18"></i>
                            </a>
                        </div>
                    </div>
                </template>


            </div>
            <div class="row">
                <div class="col-12">
                    <a class="btn btn-primary" @click="addAttributeInput">Add New Tag</a>
                </div>
            </div>
        </form>
        <div class="card-body d-flex align-items-center justify-content-center" v-else>
            <b-spinner large></b-spinner>
        </div>
        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="syncSeasonTicketAttributes" :disabled="tryingToSubmit">
                <b-spinner v-show="tryingToSubmit" small></b-spinner>
                Submit
            </b-button>
        </template>
    </b-modal>

</template>