<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import Ticketing from "@/services/Ticketing";
    import Football from "@/services/Football";
    import ECharts from "vue-echarts";
    import "echarts/lib/chart/pie";
    import "echarts/lib/chart/bar";
    import "echarts/lib/component/legend";
    import "echarts/lib/component/title";
    import "echarts/lib/component/tooltip";
    import "echarts/lib/component/polar";
    import "echarts/lib/component/toolbox";
    import "echarts/lib/component/grid";

    import ColumnVisibilityV2 from '@/components/ColumnVisibilityV2';
    import countTo from "vue-count-to";
    import modalAssignSeasonTicket from  '@/components/modals/ticketing/modalAssignSeasonTicket';
    import modalManageSeasonTicketTags from  '@/components/modals/ticketing/modalManageSeasonTicketTags';
    import ExcelJS from "exceljs";
    import _ from "underscore";


    export default {
        components: { Layout, PageHeader, "v-chart": ECharts, ColumnVisibilityV2, countTo, modalAssignSeasonTicket, modalManageSeasonTicketTags },
        page: {
            title: "Ticketing",
            meta: [
                {
                    name: "description",
                    content: appConfig.description,
                },
            ],
        },
        data() {
            return {
                error: null,
                tableData: [],
                title: "Match Tickets",
                items: [
                    {
                        text: "Ticketing",
                    },
                    {
                        text:"Match Tickets",
                        active: true,
                    }
                ],
                filterInput: {
                    season: '',
                    match:'',
                    team:'',
                },
                seasons: [],
                matches: [],
                teams:[],
                fixtures:[],
                transactionData: [],
                subscriptionData:[],
                transactionId: '',
                totalPages: 0,
                totalItems: 0,
                totalUnfilteredItems: 0,
                currentPage: 1,
                perPage: 10,
                filter: '',
                sortBy: "creation_date",
                sortDesc: true,
                showLoader:false,
                showLoader_c1:false,
                showLoader_c2:false,
                showLoader_c3:false,
                showLoader_c4:false,
                showLoader_c5:false,
                showLoader_c6:false,
                showLoader_c7:false,
                subscriptionPricing: null,
                matchPricing: null,
                subscriptionArea: null,
                matchArea: null,
                subscriptionValidCards: 0,
                subscriptionUnassignedCards: 0,
                subscriptionOtherCards: 0,
                order_line_id:'',
                serieName:'',
                matchName:'',
                matchDate:'',
                fields: [

                ],

            };
        },
        async mounted() {
            this.dashboardDefinitions('match_tickets', 'columns'); //get columns from db
           // this.getSeasonSchedule()
            this.getMatches();
        },

         async created() {

         },
        watch: {
            filter: {
                handler: _.debounce(function () {
                    // When the search input changes, load data from the backend
                    this.currentPage = 1;
                    //this.getSubscriptionMasterData()
                    this.getMatchTickets();
                }, 1500),
                deep: true // Watch for changes inside the filter object
            },

            // Watch for changes in the season filter
            'filterInput.match': {
                handler: _.debounce(function () {
                    // When the season filter changes, load data from the backend
                    this.totalUnfilteredItems= 0
                    this.totalPages= 0
                    this.totalItems= 0
                    this.currentPage= 1
                    this.getMatchStatistics()
                }, 500),
            }
        },
        methods: {

            async exportToExcel() {
                try {
                    this.exportingData = true
                    let filters = this.getFilters()
                    let exportFilters = this.setExportFilters(filters)

                  //  const response = await Ticketing.getSubscriptionMasterData(exportFilters)
                    const response = await Ticketing. this.getMatchTickets(exportFilters)
                    const responseData = response.data.data;

                    if (responseData.length > 0) {
                        const ExcelJS = require('exceljs');
                        const workbook = new ExcelJS.Workbook();
                        const worksheet = workbook.addWorksheet('SeasonTicketData');

                        const columnHeaders = [
                            { key: 'order_id', header: 'Order ID', width: 15 },
                            { key: 'order_line_id', header: 'Ticket ID', width: 15 },
                            { key: 'external_customer_id', header: 'External Customer Id', width: 15 },
                            { key: 'external_ticket_customer_id', header: 'External Ticket Customer Id', width: 15 },
                            { key: 'barcode', header: 'Barcode', width: 15 },
                            { key: 'customer_name', header: 'Customer Name', width: 30} ,
                            { key: 'email', header: 'Email', width: 30} ,
                            { key: 'area_name', header: 'Area', width: 30} ,
                            { key: 'row_name', header: 'Row', width: 30} ,
                            { key: 'seat_name', header: 'Seat', width: 30} ,
                            { key: 'status', header: 'Status', width: 30} ,
                            { key: 'creation_date', header: 'Creation Date', width: 30}
                        ];

                        worksheet.addRow(columnHeaders.map(column => column.header));
                        worksheet.getRow(1).font = { bold: true };

                        columnHeaders.forEach((column, index) => {
                            const cell = worksheet.getCell(String.fromCharCode(65 + index) + '1');
                            cell.width = column.width;
                        });

                        responseData.forEach(item => {
                            // Create a new object with the combined customer name
                            const customerName = `${item.first_name} ${item.last_name}`;
                            const status = item.status == 1 ? 'valid' : 'removed'; // Check status value
                            const rowData = columnHeaders.map(column => {
                                if (column.key === 'customer_name') {
                                    return customerName;
                                }
                                if (column.key === 'status') {
                                    return status; // Use the 'status' variable
                                }
                                return item[column.key];
                            });
                            worksheet.addRow(rowData);
                        });

                        const buffer = await workbook.xlsx.writeBuffer();
                        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                        const link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = 'SeasonTicketData.xlsx';
                        link.click();
                    } else {
                        alert('No data available to export.');
                    }
                } catch (error) {
                    alert('An error occurred while fetching data from the API.'); // Display an error message
                } finally {
                    this.exportingData = false
                }
            },


              async getMatches(){
                try {
                    this.showLoader = true
                    await Football.getMatches({

                    }).then((response) => {
                        this.matches = response.data.data.fixtures.first_team;
                        this.teams = Object.keys(response.data.data.fixtures);
                        this.fixtures = response.data.data.fixtures;
                        if(this.matches.length){
                            this.filterInput.match = this.matches[0].match_id
                            //this.getSubscriptionMasterData(true)
                            this.getMatchTickets(true)
                        }
                        if(this.teams.length){
                            this.filterInput.team = this.teams[0];

                        }
                    }).catch(error => {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg("Can not get matches")
                    }).finally(() => {
                        this.showLoader = false
                    })

                } catch (error) {
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.failedmsg("Can not get matches")
                }
            },

            getTeamMatches(){
                var selected_team = this.filterInput.team;
                this.matches = this.fixtures[selected_team];

            },

            async getMatchStatistics(){

                if(this.filterInput.match == ''){
                    return false
                }

                try {
                    this.showLoader_c1 = this.showLoader_c2 = this.showLoader_c3 = this.showLoader_c4 = this.showLoader_c5 = this.showLoader_c6 = true
                    //await this.getSubscriptionPricingData()
                    await this.getMatchPricingData()
                    //await this.getSubscriptionAreaData()
                    await this.getMatchTicketsAreaData()
                    //await this.getSubscriptionValidCards()
                   // await this.getSubscriptionUnassignedCards()
                   // await this.getSubscriptionOtherCards()
                }catch (error) {
                    this.error = error.response.data.error ? error.response.data.error : "";
                } finally {
                    this.showLoader_c1 = this.showLoader_c2 = this.showLoader_c3 = this.showLoader_c4 = this.showLoader_c5 = this.showLoader_c6 = false
                }
            },



           async getMatchTickets(firstRun = false){

                if(this.filterInput.match == ''){
                    this.showLoader_c7 = false
                    return false
                }

                const selectedMatchId = this.filterInput.match;
                //const selectedSeason = this.seasons.find(s => s.serie_id === selectedSeasonId);
                const selectedMatch = this.matches.find( m => m.match_id === selectedMatchId);
                if(selectedMatch){
                    this.matchName = selectedMatch.description;
                    this.matchDate = selectedMatch.date_time;
                }

                //if (selectedSeason) {
                //    this.serieName = selectedSeason.serie_name;
                //}

                try {
                    this.showLoader_c7 = true
                    var filters = this.getFilters()
                    let paginationFilters = this.setPaginationFilters(filters)

                    const response = await Ticketing.getMatchTickets(paginationFilters)
                    this.tableData = response.data.data
                    if(firstRun){
                        this.totalUnfilteredItems = response.data.total_items
                    }
                    this.totalItems = response.data.total_items
                    this.totalPages = response.data.total_pages

                } catch (error) {
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.tableData = []
                }finally {
                    this.showLoader_c7 = false
                }
            },

            async getMatchPricingData(){

                var filters = this.getFilters()
                const response = await Ticketing.getMatchTicketsPricingData(filters)

                if(response.data.data){
                    this.matchPricing = response.data.data
                    const keys = this.matchPricing.map(item => item.type_name);
                    var arrValues = [];

                    this.matchPricing.forEach((key) => {
                        arrValues.push({
                            value: key.sum, name : key.type_name
                        })
                    });

                    this.matchPricing = {
                        visualMap: {
                            show: false,
                        },
                        tooltip: {
                            trigger: 'item',
                          //  formatter: '{a} <br/>{b} : {c} ({d}%)',
                            formatter: ''
                        },
                        legend: {
                            orient: 'vertical',
                            left: 'left',
                            data: keys,
                            textStyle: {
                                color: '#999',
                                fontSize: 10,
                                fontWeight: 'bold',
                            }
                        },
                        color: ['#f46a6a', '#34c38f', '#50a5f1', '#f1b44c', '#556ee6', '#91cc75', '#9a60b4', '#ea7ccc'],

                        series: [
                            {
                                name: 'Match Pricing',
                                type: 'pie',
                                radius: '70%',
                                data: arrValues,
                                center: ['75%', '50%'],
                                itemStyle: {
                                    emphasis: {
                                        shadowBlur: 10,
                                        shadowOffsetX: 0,
                                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                                    }
                                },
                                 label : {
                                      show : false
                                 },
                                 labelLine : {
                                      show : false
                                 },
                            }
                        ],
                        fontSize: 12
                    };
                }else{
                    this.matchPricing = null
                }
            },

            async getMatchTicketsAreaData(){
                var filters = this.getFilters()
                const response = await Ticketing.getMatchTicketsAreaData(filters)

                if(response.data.data){
                    var matchAreaData = response.data.data
                    const keys = matchAreaData.map(item => item.area_name);
                    const values = matchAreaData.map(item => item.vTotal);

                    this.matchArea = {
                        width: '100%',
                        tooltip: {
                            trigger: 'item',
                            formatter: 'Area<br>{b} : {c}'
                        },
                        legend: {
                            orient: 'vertical',
                            right: 10,
                            top: 'center',
                        },
                        xAxis: {
                            type: 'category',
                            data: keys,
                            axisLabel: {
                                interval: 0,
                                align: 'center',
                                rotate: 30,
                                margin: 20,
                            }
                        },
                        yAxis: {},
                        series: [
                            {
                                type: 'bar',
                                data: values.map((value, index) => ({
                                    value,
                                    itemStyle: {
                                        color: ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'][index % 9]
                                    }
                                }))
                            }
                        ],
                        fontSize: 16,
                    };
                }else{
                    this.matchArea = null
                }
            },




            modalAssignSeasonTicket(data){
                this.$bvModal.show("assign_card");
                this.subscriptionData = data;
            },

            modalManageSeasonTicketTags(order_line_id){
                this.$bvModal.show("manage_season_ticket_attributes");
                this.order_line_id = order_line_id;
            },

            getFilters(){
                var filter_string = '';
                if(this.filterInput.match) {
                    filter_string += (this.filterInput.match) ? '&match_id=' + this.filterInput.match : "";
                }

                return filter_string = filter_string.substring(1);
            },

            onDropdownHide(bvEvent){
                bvEvent.preventDefault();
            },
            handlePaginationChange(){
                this.getMatchTickets()
            }
        },
        middleware: "authentication",
    };
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-12" v-if="!showLoader">
                <div class="card">
                    <div class="card-body">
                         <div class="row">
                             <div class="col-sm-4">
                                 <b-form-group label="Select Team" label-for="team" class="mb-3 text-center">
                                    <select v-model="filterInput.team" class="form-select" id="team" @change="getTeamMatches()">
                                        <option v-for="t in teams" :key="t" :value="t">{{t}}</option>
                                    </select>
                                </b-form-group>
                             </div>
                              <div class="col-sm-6">
                                 <b-form-group label="Select Match" label-for="match" class="mb-3 text-center">
                                    <select v-model="filterInput.match" class="form-select" id="match" @change="getMatchTickets(true)">
                                        <option v-for="m in matches" :key="m.match_id" :value="m.match_id">{{m.description}} ({{m.date_time}} )</option>
                                    </select>
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-body">
                        <h4 class="text-center">Match Statistics</h4><br>
                        <h3 class="text-center text-muted">{{ matchName }}</h3>
                        <h5 class="text-center text-muted">{{ matchDate }}</h5>
                    </div>
                    <div class="card-body">

                        <div class="row">
                            <div class="custom-accordion">
                                <a
                                        class="text-body fw-semibold pb-2 d-block"
                                        data-toggle="collapse"
                                        href="javascript: void(0);"
                                        role="button"
                                        aria-expanded="false"
                                        v-b-toggle.numberCharts-collapse
                                >
                                    <i class="mdi mdi-chevron-up accor-down-icon text-primary me-1"></i>Numbers and Charts
                                </a>
                                <b-collapse visible id="numberCharts-collapse">
                                    <div class="row mb-5 d-flex justify-content-around">
                                         <h5 class="text-muted mb-0">Total Match Tickets:
                                             <span data-plugin="counterup" v-if="!showLoader_c3">
                                                  <countTo :startVal="0" :endVal="totalUnfilteredItems" :duration="2000"></countTo>
                                              </span>
                                          </h5>

                                    </div>

                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="mb-3">
                                                <h5>Match Tickets Pricing</h5>
                                            </div>
                                            <v-chart :options="matchPricing" autoresize v-if="!showLoader_c1" />
                                            <div class="container-fluid d-flex justify-content-center align-items-center h-50" v-else>
                                                <b-spinner large></b-spinner>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="mb-3">
                                                <h5>Match Tickets Area</h5>
                                            </div>
                                            <v-chart :options="matchArea" autoresize v-if="!showLoader_c2" />
                                            <div class="container-fluid d-flex justify-content-center align-items-center h-50" v-else>
                                                <b-spinner large></b-spinner>
                                            </div>
                                        </div>
                                    </div>
                                </b-collapse>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-body">

                        <div class="col-md-6">
                            <ColumnVisibilityV2 class="me-2" :fields="fields" :visFields="visibleFields"></ColumnVisibilityV2>
                            <b-button
                                    variant="success"
                                    title="Export Data"
                                    @click="exportToExcel()"
                                    :disabled="exportingData"
                            >Export Data <b-spinner v-show="exportingData" small></b-spinner></b-button>
                        </div>

                        <!-- Table -->
                        <div class="table-responsive mb-0" >
                            <v-app>
                                <v-data-table
                                    :headers="visibleFields"
                                    :items="tableData"
                                    :search="filter"
                                    :items-per-page="perPage"
                                    :page="currentPage"
                                    :server-items-length="totalItems"
                                    :hide-default-footer="true"
                                    @update:page="onPageChange"
                                    @update:items-per-page="onItemsPerPageChange"
                                    @update:sort-desc="updateSortDesc"
                                    @update:sort-by="updateSortBy"
                                >

                                    <template v-slot:item.first_name="{ item }">
                                        <span v-if="item.first_name != null">
                                            {{ item.first_name + ' ' }}
                                        </span>
                                        <span v-if="item.last_name != null">
                                            {{ item.last_name }}
                                        </span>

                                    </template>

                                    <template v-slot:item.email="{ item }">
                                        <router-link title="View Customer" :to="{ path: `/customers/profile/${item.user_id}`}">
                                            {{item.email}}
                                        </router-link>
                                    </template>



                                    <template v-slot:item.status="{ item }">
                                        <div class="font-size-16" v-html="format_status(item.status == 1 ? 'valid' : 'removed')"></div>
                                    </template>

                                    <template v-slot:footer>
                                        <v-row v-if="showLoader_c7" class="mx-4">
                                            <v-col class="text-center">
                                                <v-progress-linear indeterminate color="primary"></v-progress-linear>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col >
                                                <v-pagination v-model="currentPage" :length="totalPages" :total-visible="6" @input="onPageChange" circle></v-pagination>
                                            </v-col>
                                        </v-row>
                                    </template>

                                    <template v-slot:top="{ pagination, options, updateOptions }">
                                        <v-row>
                                            <v-col md="6" sm="12">
                                                <v-data-footer :pagination="pagination" :options="options" @update:options="updateOptions" items-per-page-text="$vuetify.dataTable.itemsPerPageText" :items-per-page-options="itemsPerPageOpts"/>
                                            </v-col>
                                            <v-col md="6" sm="12">
                                                <v-text-field v-model="filter" :append-icon="filter ? 'mdi-close' : 'mdi-magnify'" @click:append="clearSearch" label="Search" single-line hide-details outlined dense></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </template>

                                </v-data-table>
                            </v-app>
                        </div>

                    </div>
                </div>
            </div>
            <div class="card-body d-flex align-items-center justify-content-center" v-else>
                <b-spinner large></b-spinner>
            </div>
        </div>

        <!-- MODALS -->
        <modalAssignSeasonTicket :subscriptionData="subscriptionData" ></modalAssignSeasonTicket>
        <modalManageSeasonTicketTags :order_line_id="order_line_id" @onRefresh="getMatches()" ></modalManageSeasonTicketTags>
        <!-- /MODALS -->

    </Layout>
</template>
