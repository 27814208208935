<script>
    import { required } from "vuelidate/lib/validators";
    import validationMessages from '@/components/validations'
    import Cashless from '@/services/Cashless';
    import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
    import _ from 'underscore';

    export default {
        components:{
            validationMessages, VueBootstrapTypeahead
        },
        props: ['subscriptionData'],
        watch: {
            'card_serial': _.debounce(function(serial) { this.getCardsAutocomplete(serial, 'serial') }, 500),
        },
        data() {
            return {
                csrf_token: localStorage.getItem('csrf_token'),
                card_serial:'',
                cashlessData: [],
                submitted: false,
                showModal: false,
                tryingToEdit: false,
            };
        },
        validations: {
            card_serial: {
                required,
            },
        },
        computed:{
            customerFullName(){
                return this.subscriptionData.first_name + ' ' + this.subscriptionData.last_name
            },
            order_line_id_data(){
                let extended_info = ' (';
                if(this.subscriptionData.area_name){
                    extended_info += ' Area: ' + this.subscriptionData.area_name;
                }
                if(this.subscriptionData.row_name){
                    extended_info += ' Row: ' + this.subscriptionData.row_name;
                }
                if(this.subscriptionData.seat_name){
                    extended_info += ' Seat: ' + this.subscriptionData.seat_name;
                }
                extended_info += ' )';

                return this.subscriptionData.order_line_id + extended_info
            },
        },
        methods: {

            async getCardsAutocomplete(query, inputTypeName) {
                this.cashlessData = []

                if(query.trim().length > 0){
                    if(inputTypeName == 'serial'){
                        query = 'serial='+query
                    }
                    const response = await Cashless.getCards(query)
                    const rawData = await response
                    var suggestions = [];
                    rawData.data.data.forEach(function (item) {
                        if(item.serial && inputTypeName === 'serial'){
                            suggestions.push({text:item.serial});
                        }
                    });

                    this.cashlessData = suggestions.filter((value, index, self) => self.map(x => x.text).indexOf(value.text) == index)
                }
            },

            async assignSeasonTickettoCard(){
                this.tryingToEdit = true;
                this.submitted = true;
                this.$v.$touch()

                if (this.$v.$invalid) {
                    this.tryingToEdit = false;
                    return;
                } else {
                    try {
                        await Cashless.associateCardToSeason({
                            serial: this.card_serial,
                            subscription_number: this.subscriptionData.order_line_id,
                            series_id: this.subscriptionData.PassePartoutId,
                            csrf_token: this.csrf_token
                        }).then((response) => {
                            this.successmsg("Change successfully saved");
                            this.closeModal();
                        }).catch(error => {
                            this.error = error.response.data.error ? error.response.data.error : "";
                            this.failedmsg(this.error)
                        }).finally(() => {
                            this.refreshData()
                            this.tryingToEdit = false;
                        })
                    } catch (error) {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg(this.error)
                    }
                }
                this.tryingToEdit = false;

            },

            refreshData() {
                this.$emit('onRefresh') //event from parent
            },

            closeModal() {
                this.showModal = false;
            },

            resetProps(){
                this.submitted = false
            }
        }
    };
</script>

<template>
    <b-modal @hidden="resetProps" v-model="showModal" id="assign_card" title="Assign Season ticket to Card" title-class="font-18" centered>
        <form @submit.prevent="assignSeasonTickettoCard">

            <b-form-group label="Card Serial" label-for="formrow-card_serial-input" class="mb-3">
                <vue-bootstrap-typeahead id="formrow-serial-input" ref="serialAutocomplete"
                                         v-model="card_serial"
                                         :data=cashlessData
                                         :serializer="s => s.text"/>
                <validationMessages v-if="submitted" :fieldName="'Card Serial'" :validationName="$v.card_serial"></validationMessages>
            </b-form-group>

            <b-form-group label="Ticket ID" label-for="formrow-order-input" class="mb-3">
                <b-form-input
                        v-model="order_line_id_data"
                        type="text"
                        id="order"
                        aria-describedby="order-feedback"
                        readonly
                >
                </b-form-input>
            </b-form-group>

            <b-form-group label="Customer" label-for="formrow-customer-input" class="mb-3">
                <b-form-input
                        v-model="customerFullName"
                        type="text"
                        id="customer"
                        aria-describedby="customer-feedback"
                        readonly
                >
                </b-form-input>
            </b-form-group>

            <b-form-group label="Season Series Name" label-for="formrow-series_name-input" class="mb-3">
                <b-form-input
                        v-model="subscriptionData.series_name"
                        type="text"
                        id="series_name"
                        aria-describedby="series_name-feedback"
                        readonly
                >
                </b-form-input>
            </b-form-group>

            <input type="hidden" name="csrf_token" v-model="csrf_token">
        </form>

        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="assignSeasonTickettoCard" :disabled="tryingToEdit">
                <b-spinner v-show="tryingToEdit" small></b-spinner>
                Assign</b-button>
        </template>
    </b-modal>
</template>